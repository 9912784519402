import i18n from '@/lang/i18n';

export const LIST_LEVEL_EVALUATE = [
        {
            name:i18n.t('casbee.option_not_consider_co2_emission'),
            value:0,
        },
        {
            name: "0",
            value: 115,
        },
        {
            name: "1",
            value: 100,  
        },
        {
            name: "2",
            value: 85,   
        },
        {
            name: "3",
            value: 70,  
        },
]